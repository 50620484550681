.form {
  background: #e8e3f3;

  border-radius: 20px;
  width: 553px;
  height: 751px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 32px;
}
.formCampos {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.formButton {
  display: flex;
  align-self: end;
}
.formButton button {
  padding: 8px 85px;
}
@media screen and (max-width: 768px) {
  .form {
    width: 334px;
    height: 450px;
    padding: 25px;
  }
  .formButton {
    display: flex;
    align-self: center;
  }
}
