.container-contact-support {
  display: flex;
  width: 86%;
  height: 100%;
  margin: 0 auto;
  margin-top: 28px;
  margin-bottom: 4%;
}

.contact-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title-contact-support {
  width: 461px;
  color: #1f384c;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 18px;
}

.text-contact-support {
  width: 461px;
  color: #1f384c;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;
}

.container-contact {
  width: 300px;
  height: auto;
  margin-bottom: 46px;
}

.subtitle-contact {
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-bottom: 28px;
}

.contact-icon {
  font-size: 28px;
}

.contact-options {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.contact-options p {
  margin-left: 18px;
}

.contact-side-form {
  width: 50%;
  display: flex;
  flex-direction: column;
}
