.modal-info-code {
  position: absolute;
  display: flex;
  width: 598px;
  height: auto;
  background-color: #ffffff;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.header-modal {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 12px;
  color: #5a6acf;
  font-size: 14px;
  font-weight: 600;
  background-color: #f1f2f7;
  border-radius: 6px 6px 0 0;
}

.btn-close {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.modal-title {
  margin-top: 48px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.modal-subtitle {
  margin-top: 18px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px; /* 108.333% */
  letter-spacing: 0.5px;
  margin-bottom: 22px;
}

.code {
  margin: 0;
  color: #898989;
}
