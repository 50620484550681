.container-form-support {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dde4f0;
  border-radius: 8px;
  background-color: white;
}

.container-input-contact {
  width: 460px;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.form-support {
  padding: 45px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.input-text {
  font-size: 14px;
  margin-bottom: 8px;
}

.input-support {
  width: 100%;
  height: 42px;
  padding: 14px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #dde4f0;
  border-radius: 6px;
}

.input-support-large {
  width: 100%;
  height: 112px;
  padding: 14px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #dde4f0;
  border-radius: 6px;
  resize: none;
}

.button-send-msg {
  cursor: pointer;
  width: 250px;
  height: 42px;
  margin-top: 6px;
  color: white;
  font-size: 14px;
  background-color: #5a6acf;
  border: none;
  border-radius: 6px;
  font-weight: 600;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content p {
  margin-bottom: 22px;
  color: #149d52;
}

.modal-content button {
  background: #5a6acf;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.modal-content button:hover {
  background: #5a6acfec;
}
