.form-profile {
  display: flex;
  width: 890px;
  margin: 0 auto;
  margin-top: 4%;
  border: 1px solid #c8cbd9;
  border-radius: 6px;
}

.form-side-img {
  width: 40%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #c8cbd9;
  align-items: center;
}

/* Ocultar el input file */
#file-upload {
  display: none;
}
/* Estilos para el contenedor del botón personalizado */
.custom-file-upload {
  position: relative;
  display: inline-block;
}
/* Estilos para el botón personalizado */
.btn-upload-img {
  width: 100%;
  height: 42px;
  padding: 0 16px;
  color: #5a6acf;
  font-size: 14px;
  background-color: #e4e7f4;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  line-height: 42px;
}
/* Estilo cuando el botón se activa */
.btn-upload-img:active {
  background-color: #5a6acf; /* Cambia el color cuando se hace clic */
  color: white;
}

.form-side-info {
  width: 60%;
  margin-left: 8%;
}

.form-side-info p {
  font-size: 14px;
  margin: 18px 0px 8px 0px;
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 42px;
}

.input-info {
  width: 80%;
  height: 42px;
  padding: 14px;
  font-size: 12px;
  border: 1px solid #dde4f0;
  border-radius: 6px;
}
.input-info::placeholder {
  font-size: 12px;
}

.container-phone {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.container-phone .input-phone {
  width: 100%;
  height: 42px;
  padding: 14px;
  font-size: 12px;
  border: 1px solid #dde4f0;
  border-radius: 6px;
}

.button-update-info {
  cursor: pointer;
  width: 80%;
  height: 42px;
  margin: 48px 0px 42px 0px;
  color: white;
  font-size: 14px;
  background-color: #5a6acf;
  border: none;
  border-radius: 6px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .form-profile {
    width: 720px;
  }

  .container-phone {
    display: flex;
    width: 84.2%;
    justify-content: space-between;
  }

  .container-phone .input-phone {
    width: 90%;
    height: 42px;
    padding: 14px;
    font-size: 12px;
    border: 1px solid #dde4f0;
    border-radius: 6px;
  }
}
