.glider-track .glider-slide {
  width: 310px !important;
}
.glider-track {
  width: fit-content !important;
}
/* .glider .draggable .drag {
  width: 228px;
}
.contenedorGlider {
  width: 80%;
  padding: 0 50px;
}
.glider {
  display: flex;
  gap: 34px;
  justify-content: space-evenly;
  width: 150%;
} */
@media screen and (max-width: 768px) {
  .glider-track {
    gap: 15px !important;
  }
}
