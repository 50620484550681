.capacita {
  display: flex;
  flex-direction: column;
  gap: 59px;
  padding-top: 156px;
  padding-bottom: 80px;
}
.capacitaTitulo {
  text-align: center;
}
.capacitaMain {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 144px;
  justify-content: space-between;
  align-items: center;
}
.mainLista ul li {
  list-style-image: url(../../../assets/iconos/listIcon.png);
}
.mainLista ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 30px;
}
.mainLista {
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 530px;
}
.mainVideo iframe {
  width: 40vw;
  height: 40vh;
}
@media screen and (max-width: 768px) {
  .capacita {
    gap: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .capacitaMain {
    flex-direction: column;
    gap: 20px;
    padding: 30px 30px 0 30px;
    justify-content: space-between;
  }
  .capacitaTitulo {
    text-align: start;
    padding: 30px 30px 0 30px;
  }
  .mainLista {
    gap: 10px;
    width: 100%;
  }
  .mainLista ul {
    margin-left: 0px;
  }
}
