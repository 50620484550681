.softwareEscalar {
  display: flex;
  padding: 136px 144px 200px 144px;
  justify-content: space-between;
}
.softwareVideo {
  width: 471px;
  display: flex;
  align-items: center;
  height: 416px;
}
.softwareTextos {
  width: 565px;
}
iframe {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .softwareEscalar {
    padding: 40px 30px;
    flex-flow: column-reverse;
    width: 90%;
  }
  .softwareVideo {
    width: 328px;
    height: 290px;
    align-self: center;
  }
  .softwareTextos {
    width: 100%;
    gap: 14px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .softwareEscalar {
    padding: 40px 30px;
    width: 90%;
    gap: 50px;
  }
}
