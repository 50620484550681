.navBar {
  display: flex;
  flex-direction: row;
  padding: 40px 113px;
  gap: 17px;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
}
.brandMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 640px;
}
.navBar .menuMedium {
  margin: 0;
}
