.container-dashboard p {
  margin-bottom: 0;
}

.container-dashboard {
  width: 94%;
  margin: 40px auto;
  /* border: 1px solid #c8cbd9; */
}

.container-title-employee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-info-employee {
  display: flex;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 191.667% */
  letter-spacing: 0.5px;
}

.container-info-employee p:first-child {
  margin-right: 22px;
}

.title-employee {
  color: #1f384c;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.status-employee {
  padding: 12px 18px;
  color: #5a6acf;
  font-size: 14px;
  border: 1px solid #737b8b;
  border-radius: 6px;
  margin-bottom: 0;
}

/* Tabs.css */
.tabs-container {
  display: flex;
  margin-top: 22px;
  margin-bottom: 8px;
}

.tab {
  padding: 6px 8px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 12px;
}

.tab.active {
  color: #5a6acf;
  background-color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #5a6acf;
}

.score-dashboard {
  font-size: 20px;
  font-weight: 500;
}

/* Dashboard charts */
.container-dashboard-charts {
  width: 100%;
  height: 63vh;
  display: grid;
  grid-template-rows: 1fr 2fr; /* Dos filas, la primera ocupa 1 parte y la segunda 2 partes */
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Tres columnas, cada una ocupa 1 parte */
  grid-gap: 10px;
  background-color: #fff;
}

.chart {
  /* border: 1px solid #c8cbd9; */
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
  /* width: 100%; O ajusta el ancho según tus necesidades */
  /* height: 100%; O ajusta la altura según tus necesidades */
}

.a {
  height: 260px;
  grid-row: 1 / span 1; /* Ocupa una fila a partir de la primera fila */
  grid-column: 1 / span 3; /* Ocupa tres columnas a partir de la primera columna */
  padding-bottom: 32px;
  border-bottom: 1px solid #c8cbd9;
  border-radius: 0;
}

.b {
  height: 240px;
  grid-row: 2 / span 1; /* Ocupa una fila a partir de la segunda fila */
  grid-column: 1 / span 2; /* Ocupa dos columnas a partir de la primera columna */
  border-right: 1px solid #c8cbd9;
  border-radius: 0;
}

.c {
  height: 240px;
  grid-row: 2 / span 1; /* Ocupa una fila a partir de la segunda fila */
  grid-column: 3 / span 1; /* Ocupa una columna a partir de la tercera columna */
}

.no-data-message {
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 20px;
}
