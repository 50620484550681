.container-employees {
  width: 94%;
  margin: 40px auto;
}

.container-employees .title {
  color: #1f384c;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  margin-bottom: 34px;
}

.container-inputs-employees {
  display: flex;
  width: 100%;
  height: 32px;
  justify-content: space-between;
  margin-bottom: 36px;
}

.container-search-employees {
  display: flex;
  width: 100%;
}
