.header {
  display: flex;
  padding: 136px 30px 200px 144px;
}
.headerTitle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.headerTitle button {
  width: fit-content;
}
.headerImg img {
  width: 600px;
}
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 40px 30px;
    gap: 30px;
  }
  .header img {
    width: 100%;
  }
  .headerTitle {
    gap: 0;
  }
  .headerTitle .subtituloMedium {
    margin-top: 24px;
  }
  .headerTitle .btnPrincipal {
    margin-top: 24px;
  }
}
