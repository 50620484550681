.transformamos {
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 136px 144px 50px 144px;
  align-items: center;
  background-image: url(../../../assets/backgroundADN.png);
  background-size: cover;
  background-position-y: -50px;
}
.overline {
  background: linear-gradient(
    90.67deg,
    #511ea5 -13.21%,
    #9b58ff 46.83%,
    #41f6ec 119.29%
  );
  opacity: 0.8;
  border-radius: 4px;
  width: 176px;
  height: 7.71px;
}
.textosNumero {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 275px;
}
.transformamosTextos {
  gap: 160px;
  justify-content: space-between;
  align-items: center;
}
.transformamosTextos .cuerpoBlanco {
  width: 90%;
}
@media screen and (max-width: 768px) {
  .transformamos {
    padding: 50px 30px;
    background-color: #24165b;
    clip-path: polygon(
      100% 100%,
      0% 100%,
      0% 4.27%,
      2% 4%,
      4% 3.73%,
      6% 3.47%,
      8% 3.21%,
      10% 2.96%,
      12% 2.72%,
      14% 2.49%,
      16% 2.28%,
      18% 2.08%,
      20% 1.9%,
      22% 1.73%,
      24% 1.59%,
      26% 1.47%,
      28% 1.36%,
      30% 1.29%,
      32% 1.23%,
      34% 1.2%,
      36% 1.19%,
      38% 1.2%,
      40% 1.24%,
      42% 1.31%,
      44% 1.39%,
      46% 1.5%,
      48% 1.63%,
      50% 1.78%,
      52% 1.95%,
      54% 2.13%,
      56% 2.34%,
      58% 2.55%,
      60% 2.78%,
      62% 3.03%,
      64% 3.28%,
      66% 3.54%,
      68% 3.81%,
      70% 4.07%,
      72% 4.35%,
      74% 4.62%,
      76% 4.88%,
      78% 5.15%,
      80% 5.4%,
      82% 5.65%,
      84% 5.89%,
      86% 6.11%,
      88% 6.32%,
      90% 6.51%,
      92% 6.69%,
      94% 6.85%,
      96% 6.99%,
      98% 7.1%,
      100% 7.2%
    );
  }
  .transformamosTextos {
    flex-direction: column;
    gap: 16px;
  }
  .textosNumero {
    text-align: center;
  }
  .transformamos .overline {
    align-self: center;
  }
}
