.btn-add-employee {
  cursor: pointer;
  width: 200px;
  padding: 0px 16px;
  color: #5a6acf;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.5px;
  border: none;
  border-radius: 6px;
  background-color: #e4e7f4;
  filter: drop-shadow(0px 2px 1px rgba(64, 72, 82, 0.05));
}
