.table-dashboard-employees {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #c8cbd9;
  border-radius: 6px;
  overflow: hidden;
  caption-side: unset;
  border-collapse: unset;
}

.table-title {
  height: 34px;
  background: #e4e7f4;
}

.table-title th {
  color: #5a6acf;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  padding-left: 38px;
  line-height: 12px; /* 100% */
  letter-spacing: 0.5px;
}

.table-item {
  cursor: default;
  border-top: #c8cbd9;
}

.table-item:hover {
  background-color: #5a6acf1a;
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.status-finalizado {
  background-color: #149d52; /* Color para "Finalizado" */
}

.status-encurso {
  background-color: #3e84c4; /* Color para "En curso" */
}

.status-enpausa {
  background-color: #f99c30; /* Color para "En pausa" */
}
.status-sininiciar {
  background-color: #a8a8a8;
}

td {
  padding: 20px 0px 20px 38px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px; /* 108.333% */
  letter-spacing: 0.5px;
}
.container-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 12px;
  color: #273240;
  font-size: 18px;
}

.icon-table {
  cursor: pointer;
  margin: 0px 4px;
}

.background-item {
  background: #f6f6fb;
}

/* TableEmployees.css */

.pagination {
  display: flex;
  width: 220px;
  height: 35px;
  color: #5a6acf;
  justify-content: space-around;
  align-items: center;
  margin: 36px auto 0px;
  border-radius: 16px;
  border: 1px solid #dde4f0;
  background: #fbfcfe;
}

.pagination .prev-page,
.pagination .next-page {
  width: 32px;
  border: none;
  color: #5a6acf;
  background: #fbfcfe;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
}

.current-page {
  font-size: 14px;
  padding: 0px 32px;
  border-left: 1px solid #5a6acf;
  border-right: 1px solid #5a6acf;
}
