.container-faq {
  width: 94%;
  height: 80vh;
  margin: 0 auto;
  margin-top: 40px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.title-faq {
  color: #1f384c;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  margin-bottom: 34px;
}
