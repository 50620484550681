.container-profile {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.img-profile {
  width: 32px;
}

.name-profile {
  color: #1f384c;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px; /* 108.333% */
  letter-spacing: 0.5px;
  margin: 0px 12px;
}

.dropdown-menu-dashboard {
  position: absolute;
  width: 200px;
  top: calc(100% + 5px);
  right: 0;
  background-color: #ffffff;
  border: 1px solid #dde4f0;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu-dashboard ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu-dashboard ul li {
  font-size: 14px;
  padding: 12px 22px;
  cursor: pointer;
}

.dropdown-menu-dashboard ul li:hover {
  background-color: #f5f5f5; /* Color de fondo al pasar el mouse */
}
