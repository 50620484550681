.loginPage {
  width: 100%;
  height: calc(100vh - 130px);
  padding-top: 34px;
}

.containerLogin {
  max-width: 538px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 70px;
  border: 1px solid #dde4f0;
  border-radius: 6px;
}

.title {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 32px;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 28px;
}

.inputContainer {
  width: 100%;
  margin-bottom: 14px;
}

.inputLogin {
  width: 100%;
  height: 42px;
  padding: 14px;
  margin: 8px 0px;
  font-size: 16px;
  border: 1px solid #dde4f0;
  border-radius: 6px;
}
.inputLogin::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #b0b0b0;
}

.buttonLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 42px;
  margin-top: 26px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #5a6acf;
  border: none;
  border-radius: 6px;
}

.buttonLogin a {
  color: white;
}

.helpTextLogin {
  margin-top: 18px;
  font-size: 12px;
  text-align: center;
}

.helpTextLogin a {
  color: #6463d6;
  font-weight: 600;
  text-decoration: none;
}

.errorMsg {
  display: flex;
  width: 100%;
  height: 42px;
  margin: 12px 0px 0px 0px;
  align-items: center;
  justify-content: center;
  color: #f2383a;
  font-size: 14px;
  border-radius: 6px;
  border: 0.5px solid #f2383a;
  background: #febac6;
}

/* Mensaje para dispositivos moviles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.mobileAlert {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 300px;
}

.mobileAlert p {
  margin: 0;
}
