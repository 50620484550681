.form-new-user {
  position: absolute;
  display: flex;
  width: 520px;
  height: auto;
  background-color: #ffffff;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.header-form {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 12px;
  color: #5a6acf;
  font-size: 14px;
  font-weight: 600;
  background-color: #f1f2f7;
  border-radius: 6px 6px 0 0;
}

.header-form p {
  margin: 0;
}

.btn-close {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.container-input {
  width: 74%;
  align-self: center;
  margin-top: 42px;
}

label {
  font-size: 12px;
}

.input-form {
  width: 100%;
  height: 42px;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #dde4f0;
  border-radius: 6px;
  font-size: 13.33px;
  font-weight: 400;
}

.input-form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
  width: 74%;
  margin-top: 18px;
}

.input-department,
.input-position {
  width: 47%;
}

.input-training-status {
  width: 100%;
  margin-top: 18px;
}

.button-form {
  width: 74%;
  align-self: center;
  height: 42px;
  margin: 42px 0px;
  background-color: #5a6acf;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color oscuro con transparencia */
  z-index: 999; /* Asegura que esté por encima de otros elementos */
}
