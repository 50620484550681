.modal-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  padding: 48px 22px 22px;
  margin: 0 auto;
  margin-top: 10%;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #c8cbd9;
}

.modal-video p {
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  color: #5a6acf;
  top: 16px;
  right: 22px;
  cursor: pointer;
}

video {
  border-radius: 6px;
}
