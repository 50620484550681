.cardCarrusel {
  background-image: url(../../../assets/card-bg.png);
  width: 300px;
  height: 333.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 36px 20px 36px;
}
