@import "bootstrap/dist/css/bootstrap.min.css";
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
.background-icaro {
  background-image: url(../src/assets/background-home.png);
  background-size: cover;
}

.container-main {
  display: flex;
}
.container-section {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .background-icaro {
    overflow-x: hidden;
    background-image: url(../src/assets/background-mobile.png);
  }
  .navBar {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .navBarMobile {
    display: none !important;
  }
}
@media (min-width: 1500px) {
  .glider-contain {
    text-align: -webkit-center;
  }
}
