.navbar-nav {
  align-items: center;
  padding: 20px;
  height: 100vh;
  justify-content: space-evenly;
}
.navbar {
  background-image: url(../../../assets/bg-navbar.png);
  background-size: cover;
  height: 100%;
}
