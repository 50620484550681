.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 155px;
  padding: 44px 206px;
  background-color: #24165b;
}
.footerContacto ul li {
  display: flex;
  list-style-type: none;
  align-items: center;
  gap: 10px;
}
.footerContacto {
  width: 280px;
}
.footer ul {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.footerDireccion {
  display: flex;
  width: 280px;
  align-items: center;
  gap: 10px;
}
.lineFooter {
  width: 100%;
  height: 5px;
  background-color: #753bd1;
}
.footerLogo img {
  width: 160px;
}
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 40px 30px;
    text-align: center;
    gap: 60px;
    align-items: center;
  }
  .footerDireccion img {
    display: none;
  }
  .footerContacto ul {
    align-items: center;
    padding: 0;
  }
}
