.modal-code-success {
  position: absolute;
  display: flex;
  width: 598px;
  height: auto;
  background-color: #ffffff;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.header-modal {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 12px;
  color: #5a6acf;
  font-size: 14px;
  font-weight: 600;
  background-color: #f1f2f7;
  border-radius: 6px 6px 0 0;
}

.header-modal p {
  margin: 0;
}

.btn-close {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.check-icon-modal {
  font-size: 110px;
  color: #149d52;
  margin: 0 auto;
  margin-top: 22px;
  margin-bottom: 18px;
}

.modal-title-success {
  color: #149d52;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 13px; /* 81.25% */
  letter-spacing: 0.5px;
  margin-bottom: 18px;
}

.modal-subtitle {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px; /* 108.333% */
  letter-spacing: 0.5px;
  margin-bottom: 22px;
}

.container-code {
  display: flex;
  width: 75%;
  height: 42px;
  margin-bottom: 26px;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 6px;
  border: 1px solid #dde4f0;
  background: #f6f6fb;
}

.code {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.5px;
}

.btn-success {
  cursor: pointer;
  width: 75%;
  height: 42px;
  margin-bottom: 28px;
  align-self: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #5a6acf;
  border: none;
  border-radius: 6px;
}
