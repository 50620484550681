.contacto {
  display: flex;
  gap: 125px;
  padding: 114px 144px;
}
.contacto .titulo {
  width: 80%;
}
.contactoTexto .subtituloMedium {
  margin-top: 25px;
}
@media screen and (max-width: 768px) {
  .contacto {
    padding: 30px 28px;
    flex-direction: column;
    gap: 20px;
  }
  .contactoForm {
    align-self: center;
  }
}
