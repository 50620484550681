.card {
  width: 310px;
  height: 380px;
  border-radius: 6px;
  border: 1px solid #dde4f0;
  background: #fff;
  margin-right: 3%;
  margin-bottom: 22px;
}

.card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #dde4f0;
  background: #f6f6fb;
}

.card-title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 24px;
}

.card-subtitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 0px 0px 12px 24px;
}

.card-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 38px;
  margin: 10px 24px;
  color: #149d52;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid #149d52;
  background: rgba(20, 157, 82, 0.1);
}

.status-text {
  font-weight: 400;
  margin-left: 8px;
  letter-spacing: 0.5px;
}
