.conteiner-card-question {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c8cbd9;
  border-radius: 6px;
  margin-bottom: 12px;
}

.card-question {
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
}

.card-question .expanded {
  height: auto; /* Expandir la tarjeta */
}

.number-question {
  padding: 0px 32px;
}

.title-question {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.title-question p {
  margin: 0;
}

.btn-expand {
  display: flex;
  width: 62px;
  height: 100%;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  background: #5a6acf;
  border-radius: 0px 6px 6px 0px;
}

.card-content {
  width: 100%;
  padding: 18px 80px 32px 80px;
  font-size: 14px;
}
.card-content p {
  margin: 0;
}
