.titulo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;
  margin: 0;
}
.tituloOscuro {
  color: #14044d;
    margin: 0;
}
.tituloClaro {
  color: #48cbd9;
    margin: 0;
}
.tituloLila {
  color: #a848da;
}
.tituloBlanco {
  color: #fff;
}
.tituloNumeros {
  font-weight: 900;
  font-size: 64px;
  line-height: 78px;

  color: #ffffff;
}
.transformamosTextos {
  display: flex;
}
@media screen and (max-width: 768px) {
  .titulo {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }
}
