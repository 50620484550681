.container-home {
  width: 94%;
  margin: 40px auto;
  /* border: 1px solid #c8cbd9; */
}

.container-home .title {
  color: #1f384c;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  margin-bottom: 34px;
}

.container-cards {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 36px;
}
