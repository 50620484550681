.subtituloBold {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.0025em;

  color: #a848da;
}
.subtituloMedium {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;

  color: #000000;
}
.subtituloCapacita {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.0025em;

  color: #000;
}
@media screen and (max-width: 768px) {
  .subtituloBold {
    font-weight: 700;
    font-size: 20px;
  }
  .subtituloMedium {
    font-weight: 500;
    width: 90%;
    font-size: 18px;
  }
  .subtituloCapacita {
    font-weight: 700;
    font-size: 20px;
  }
}
