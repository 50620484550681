.container-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  height: 32px;
  padding: 0 16px;
  border-radius: 5px;
  border: 1px solid #dde4f0;
  background: #f6f6fb;
}

.input-search {
  width: 95%;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 13.33px;
  font-weight: 400;
  outline: none;
  padding: 0;
}

.input-search::placeholder {
  opacity: 0.3;
  font-size: 13.33px;
  font-weight: 400;
  padding: 0;
}

.search-icon {
  opacity: 0.3;
}
