.sidebar {
  width: 290px;
  height: 100vh;
  background-color: #f1f2f7;
}

.sidebar-logo {
  margin: 20px 0px 20px 40px;
}

.sidebar-subtitle {
  color: #777f8a;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 30px 0px 12px 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0px 20px;
}

.link-item {
  display: flex;
  align-items: center;
  width: 100%;
  color: #777f8a;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 6px;
}

.sidebar .active {
  color: #5a6acf;
  font-weight: 500;
  background-color: #e4e6f5;
}

.sidebar .active .sidebar-icon {
  color: #5a6acf;
}

.sidebar .sidebar-item a:hover {
  color: #777f8a;
}

.sidebar-item {
  display: flex;
  cursor: pointer;
}

.sidebar-icon {
  color: #c4c6dd;
  font-size: 18px;
  margin-right: 12px;
}
